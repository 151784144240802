<template>
  <div class='container'>
    <div class="content">
      <div class="option">
        <div class="title">学习经历</div>
        <div class="select">
          <input type="text" v-model="listQuery.time_at" placeholder="请选择开始时间" @click="isShow=true" readonly>
          <van-icon class="arrow" name="arrow-down" size="5.3333vw"/>
        </div>
      </div>
      <div class="option">
        <div class="title">事件经过</div>
        <textarea class="area" v-model="listQuery.things" placeholder="请描述事件发展的经过"></textarea>
      </div>
    </div>
    <div class="bottom">
      <button @click="submit">保存</button>
    </div>
    <van-popup v-model="isShow" position="bottom" :style="{ height: '40%' }" >
      <van-datetime-picker
        type="date"
        title="选择事情发生时间'"
        show-toolbar
        v-model="currentDate"
        :max-date="maxDate"
        :min-date="minDate"
        @confirm="onConfirm"
        @cancel="isShow=false"
      />
    </van-popup>
  </div>
</template>

<script>
import moment from 'moment'
import {mapState} from 'vuex'
import {profileUpdateV2} from '@/api/student.js'
export default {
  data(){
    return{
      isShow:false,
      currentDate:new Date(),
      maxDate:new Date(),
      minDate:new Date(1900, 1, 1),
      isShow:false,
      listQuery:{
        things:'',
        time_at:''
      },
      formData: {
          id: '',
          sn: '', // 学籍号
          grade: '', // 年级
          studyClass: '', // 班级
          gender: '', // 性别
          area: '', // 校区
          birthday: '', // 出生日期
          nation: '', // 民族
          birth_place: '', // 籍贯
          situation: '', // 学习情况
          interest: [], // 兴趣
          interest_other: '', // 兴趣
          home_address: '', // 家庭地址
          health_status: '', // 健康状况
          psychological_diagnsis: '', // 心理诊断记录
          past_medical_history: [], // 过往身体病史
          past_medical_history_other: '', // 过往身体病史
          parent_relationship: '', // 父母关系
          family_atmosphere: '', // 家庭气氛
          family_economy: '', // 家庭经济情况
          other: '', // 其他情况
          f_name: '', // 父亲名字
          f_age: '', // 年龄
          f_occupation: '', // 职业
          f_characteristics: '', // 个人特点
          f_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          m_name: '', // 母亲名字
          m_age: '', // 年龄
          m_occupation: '', // 职业
          m_characteristics: '', // 个人特点
          m_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          study: [], // 学习经历
          event: [], // 重大生活事件
          home: [], // 家庭成员
          province: '', // 省
          city: '', // 市
          county: '' // 区
        },
    }
  },
  async created(){
    console.log(this.listQuery);
    await this.getDetail()
    this.getInfo()
  },
  computed:{
    ...mapState(['student']),
  },
  methods:{
    getInfo(){
      const id = this.$route.query.eventId
      if(id){
        this.listQuery=this.formData.event.filter(item=>item.id==id)[0]
      }
      // console.log(this.formData.home.filter(item=>item.id!=id));
    },
    async getDetail(){
      const {id}=this.$route.query
      await this.$store.dispatch('getStudentDetail',id)
      this.formData=this.student
    },
    onConfirm(val){
      const date = moment(val, 'ddd MMM DD YYYY HH:mm:ss [GMT]Z');
      this.listQuery.time_at=date.format('YYYY-MM-DD')
      this.isShow=false
    },
    async submit(){
      if(!this.listQuery.time_at){
        this.$toast.fail('请选择事情发生时间')
        return
      }
      if(!this.listQuery.things){
        this.$toast.fail('事情经过不能为空')
        return
      }
      const formData=this.formData
      const id=this.$route.query.eventId
      if(id){
        this.formData.event.forEach(item=>{
          if(item.id==id)item=this.listQuery
        })
      }else{
        formData.event.push(this.listQuery)
      }
      const res = await profileUpdateV2(formData)
      this.$toast.success('修改成功')
      this.$router.back()
    },
    liveChange(index){
      this.listQuery.groups_like_level=this.like[index]
    },
    
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 5.3333vw 0 16vw 0;
  .content{
    padding: 0 4vw;
    .option{
      margin-bottom: 4vw;
      .title{
        font-weight: 550;
        margin-bottom: 3.4667vw;
      }
      .select{
        position: relative;
        input{
          padding-right: 10.6667vw;
        }
        .arrow{
          position: absolute;
          top: 50%;
          right: 4vw;
          transform: translateY(-50%);
        }
      }
      input{
        padding: 0 6.1333vw;
        width: 100% ;
        height: 12.2667vw;
        border-radius: 13.3333vw;
        background: #F5F7F9;
      }
      textarea{
        padding: 4vw;
        width: 100%;
        min-height: 53.3333vw;
        background: #F5F7F9;
        border-radius: 1.3333vw;
        font-weight: 550;
      }
    }
  }
  .bottom{
    position: fixed;
    display: flex;
    align-items: center;
    padding: 0 4vw;
    bottom: 0;
    width: 100%;
    height: 16vw;
    button{
      width: 100%;
      color: #fff;
      height:13.8667vw;
      line-height: 13.8667vw;
      font-size: 4vw;
      border-radius: 13.3333vw;
      background: #5EDBC9;
    }
  }
}
</style>